import { useToken } from "antd/es/theme/internal";
import { useAuth, getStorageItem } from "../../Services/auth";
import { useHistory, useLocation } from "react-router-dom";

function getToken() {
    let token = null;
    if (localStorage.getItem("isRememberMe") === "true") {
        token = localStorage.getItem("token");
    } else {
        token = sessionStorage.getItem("token");
    }
    return token;
}

function AuthGuard({ children }) {
    const auth = useAuth();
    const location = useLocation();
    const history = useHistory();
    const userToken = getToken();
    
    if (!userToken) {
        console.log(location.pathname);
        // console.log("LOGOUTED");
        if (location.pathname !== "/auth/signin") {
            history.push("/auth/signin");
        }
    } else {
        if (location.pathname == "/auth/signin" || location.pathname == "/") {
            // var userType = getStorageItem("userType");
            // console.log(userType);
            // if (userType == 1) {
                history.replace("/dashboard/default");
            // } else if (userType == 3) {
            //     console.log("CLIENT DASHBOARD");
            //     history.replace("/clients");
            // } else if (userType == 4) {
            //     console.log("LANDLORD DASHBOARD");
            //     history.replace("/landlord-dashboard");
            // }
        }
    } 

    return children;
}

export default AuthGuard