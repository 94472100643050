import React, { useState,useEffect,Component, Suspense, } from 'react';
import { Switch, Route, Routes } from 'react-router-dom';
import Loadable from 'react-loadable';
import { toast, ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import '../../node_modules/font-awesome/scss/font-awesome.scss';

import Loader from './layout/Loader'
import Aux from "../hoc/_Aux";
import ScrollToTop from './layout/ScrollToTop';
import routes from "../route";
import { AuthProvider } from '../Services/auth';
import AuthGuard from '../App/components/AuthGuard';

import favicon from '../assets/images/brand/favicon.png'
import offlineIco from '../assets/images/brand/fav.png'
const AdminLayout = Loadable({
    loader: () => import('./layout/AdminLayout'),
    loading: Loader
});

// const Screen =Loadable({
//     loader:()=> import('../pages/Screenweb/WebScreen'),
//     loading:Loader
// });



const App = () => {

    const [isOnline, setIsOnline] = useState(navigator.onLine);

    useEffect(() => {
        function onlineHandler() {
            setIsOnline(true);
        }

        function offlineHandler() {
            setIsOnline(false);
        }

        window.addEventListener("online", onlineHandler);
        window.addEventListener("offline", offlineHandler);


        return () => {
            window.removeEventListener("online", onlineHandler);
            window.removeEventListener("offline", offlineHandler);
        };
    }, []);

    useEffect(() => {
        const faviconLink = document.getElementById("favicon");
        if (faviconLink) {
            faviconLink.href = isOnline ? favicon : offlineIco;
        }
    }, []);
    // render() {
    const menu = routes.map((route, index) => {
        return (route.component) ? (
            <Route
                key={index}
                path={route.path}
                exact={route.exact}
                name={route.name}
                render={props => (
                    <route.component {...props} />
                )} />

        ) : (null);
    });

    return (
        <Aux>
            <AuthProvider>
                <AuthGuard>
                    <ScrollToTop>
                        <ToastContainer />
                        <Suspense fallback={<Loader />}>
                            <Switch>
                                {menu}
                                <Route path="/" component={AdminLayout} />

                            </Switch>
                            {/* <Switch>
                        {menu}
                        <Route path="/App/Screenweb/Webscreen" component={Screen}/>
                    </Switch> */}
                        </Suspense>
                    </ScrollToTop>
                </AuthGuard>
            </AuthProvider>
        </Aux>
    );

}

export default App;
